const size = {
  small: '320px',
  medium: '768px',
  large: '1024px',
  wide: '2560px',
}

export const media = {
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  wide: `(min-width: ${size.wide})`,
};
