import { css, createGlobalStyle } from 'styled-components';

export const reset = css`
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  font-family: Open Sans;
  line-height: 1;
  background: #f5f5f5;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* some unreset stuff */
p {
  margin: 1rem 0;
  line-height: 1.4;
}
img {
  max-width: 100%;
}
/* Site styles */

i, em {
  font-style: italic;
}

bold, strong {
  font-weight: bold;
}
blockquote {
  padding-left: 3rem;
  margin: 3rem 0;
}

cite {
  color: gray;
}

h2 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 2rem 0;
}
`
export const Reset = createGlobalStyle`${reset}`

export default reset
