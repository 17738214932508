import styled, { css }  from "styled-components"

import { media } from '../../styles/mixins.css';

export const Container = styled.div`
  margin: 0 1rem;
`;

export const Main = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

export const Content = styled.div`
  ${(props) => props.page === `home` && css`
    @media ${media.medium} {
      display: flex;
    }
  `}

  ${(props) => props.page === `blog` && css`
    max-width: 730px;
    margin: 0 auto;
  `}
`;
