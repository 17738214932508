import styled from "styled-components"

import { media } from '../../styles/mixins.css';

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 1rem auto;
  border-bottom: 1px solid black;
  border-left: 1rem solid #d64c4c;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  max-width: 1200px;

  @media ${media.medium} {
    max-width: calc(1200px - 2rem);
  }
`;

export const Logo = styled.div`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;

  a {
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
  }
`;

export const NavigationItems = styled.ul`
  display: flex;
  align-self: flex-end;

  li {
    padding: 0 0.25rem;
  }

  a {
    color: black;
    text-decoration: none;
  }
`;
