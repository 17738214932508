import React from "react";
import PropTypes from "prop-types";

import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import { Reset } from '../../styles/reset.css';

import {
  Container,
  Content,
  Main,
} from './Layout.css';

const Layout = ({ children, page }) => {
  return (
    <>
      <Nav />
      <Container>
        <Reset />
        <Main>
          <Content page={page}>
            {children}
          </Content>
        </Main>
      </Container>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
