import React from "react";
import { Link } from "gatsby";

import {
  Logo,
  Navigation,
  NavigationItems,
} from './Nav.css';

const Nav = () => (
  <Navigation>
    <Logo>
      <Link to="/">
        <span>Comic</span>
        <span>Book</span>
        <span>Wiki</span>
      </Link>
    </Logo>

    <NavigationItems>
      <li>
        <Link to="/news">News</Link>
      </li>
      <li>
        <Link to="/reviews">Reviews</Link>
      </li>
      <li>
        <Link to="/articles">Articles</Link>
      </li>
    </NavigationItems>
  </Navigation>
)

export default Nav;
