import styled  from "styled-components"

import { media } from '../../styles/mixins.css';

export const Container = styled.footer`
  max-width: 1200px;
  margin: 1rem auto 0 auto;
  padding: 1rem;
  background: #fff;
  border-left: 1rem solid #d64c4c;

  @media ${media.medium} {
    max-width: calc(1200px - 2rem);
  }

  a {
    color: #000;
  }
`;
