import React from "react";

import {
  Container,
} from './Footer.css';

const Footer = () => (
  <Container>
    © {new Date().getFullYear()}
    {` `}
    <a href="/">Comic Book Wiki</a>
  </Container>
)

export default Footer;
